@use 'color-schema' as cs;

.mat-menu-base.mat-menu-base {
  box-shadow: 0 3px 20px #00000029;
  background-color: cs.$color-white;
  border-radius: 8px;

  &.user-menu {
    margin-top: 12px;
  }

  .mat-mdc-menu-content {
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 2px
  }

  .mat-mdc-menu-item {
    min-height: unset;
    padding: 7px 15px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Avenir Book', sans-serif;
    color: cs.$text-color;
    background-color: cs.$color-white;

    &:hover {
      color: cs.$color-white;
      background-color: cs.$color-primary-dark;

      i:before {
        color: inherit;
      }

      .mat-icon {
        color: cs.$color-white;
      }
    }

    i {
      margin-left: 5px;
      font-size: 24px;

      &:before {
        color: cs.$color-primary-dark;
      }
    }

    .mat-mdc-menu-item-text {
      display: flex;
      gap: 15px;
      align-items: center;
      font: inherit;
    }

    .mat-icon {
      margin-right: 15px;
      margin-left: 5px;

      color: cs.$color-primary-dark;
    }
  }
}

div.basic-drop-down-list.basic-drop-down-list ,
div.mat-mdc-select-panel.mat-mdc-select-panel {
  padding: 5px;
  box-shadow: 0 3px 20px #00000029;
  border-radius: 8px;
  gap: 2px;
  display: flex;
  flex-direction: column;
  background-color: cs.$color-white;

  .mat-mdc-option {
    font-size: 16px;
    line-height: 20px;
    font-family: 'Avenir Medium', sans-serif;
    font-weight: normal;
    letter-spacing: 0;
    min-height: 30px;
    padding: 7px 15px;
    border-radius: 8px;
    color: cs.$text-color;

    &.mat-mdc-option-active {
      background-color: inherit;
    }

    &:hover:not(.mdc-list-item--disabled) {
      background-color: rgba(cs.$color-primary-light, 0.2);
    }

    &.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
      background-color: cs.$color-primary-dark;

      .mdc-list-item__primary-text {
        color: cs.$color-white;
      }

      .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
        color: cs.$color-white;
      }
    }
  }
}
