.df {
  display: flex;
  flex-direction: row;
}

.df-c {
  display: flex;
  flex-direction: column;
}

.df-rr {
  display: flex;
  flex-direction: row-reverse;
}

.grow {
  flex-grow: 1;
}

.fw-w {
  flex-wrap: wrap;
}

.jc-s {
  justify-content: flex-start;
}

.jc-sb {
  justify-content: space-between;
}

.jc-c {
  justify-content: center;
}

.jc-e {
  justify-content: flex-end;
}

.ai-s {
  align-items: flex-start;
}

.ai-c {
  align-items: center;
}

.ai-e {
  align-items: flex-end;
}

.ai-b {
  align-items: baseline;
}

.gap5 {
  gap: 5px;
}

.gap10 {
  gap: 10px;
}

.gap15 {
  gap: 15px;
}

.gap20 {
  gap: 20px;
}

.gap25 {
  gap: 25px;
}

.gap30 {
  gap: 30px;
}

.gap35 {
  gap: 35px;
}

.gap40 {
  gap: 40px;
}
