@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?1ddlfv');
  src:  url('fonts/icomoon.eot?1ddlfv#iefix') format('embedded-opentype'),
  url('fonts/icomoon.ttf?1ddlfv') format('truetype'),
  url('fonts/icomoon.woff?1ddlfv') format('woff'),
  url('fonts/icomoon.svg?1ddlfv#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-zoom_in:before {
  content: "\e900";
  color: #fff;
}
.icon-zoom_out:before {
  content: "\e901";
  color: #fff;
}
.icon-undo:before {
  content: "\e902";
  color: #fff;
}
.icon-redo:before {
  content: "\e903";
  color: #fff;
}
.icon-actions-delete:before {
  content: "\e904";
  color: #2463ac;
}
.icon-actions-edit:before {
  content: "\e905";
  color: #fff;
}
.icon-actions-open:before {
  content: "\e906";
  color: #2463ac;
}
.icon-actions-tracking:before {
  content: "\e907";
  color: #2463ac;
}
.icon-add .path1:before {
  content: "\e908";
  color: rgb(170, 177, 204);
}
.icon-add .path2:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-add-new:before {
  content: "\e90a";
  color: #fff;
}
.icon-box-truck:before {
  content: "\e90b";
  color: #aab1cc;
}
.icon-calendar:before {
  content: "\e90c";
  color: #aab1cc;
}
.icon-car:before {
  content: "\e90d";
  color: #4caf50;
}
.icon-cargo-van:before {
  content: "\e90e";
  color: #fff;
}
.icon-checkbox .path1:before {
  content: "\e90f";
  color: rgb(86, 133, 188);
}
.icon-checkbox .path2:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-copy:before {
  content: "\e911";
  color: #2463ac;
}
.icon-delete:before {
  content: "\e912";
  color: #6b6f81;
}
.icon-dialog-close:before {
  content: "\e913";
  color: #6b6f81;
}
.icon-drag:before {
  content: "\e914";
  color: #aab1cc;
}
.icon-drop-off .path1:before {
  content: "\e915";
  color: rgb(77, 175, 80);
}
.icon-drop-off .path2:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(76, 175, 80);
}
.icon-edit .path1:before {
  content: "\e917";
  color: rgb(170, 177, 204);
}
.icon-edit .path2:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-edit-profile:before {
  content: "\e919";
  color: #2463ac;
}
.icon-field-cancel:before {
  content: "\e91a";
  color: #6b6f81;
}
.icon-file-img .path1:before {
  content: "\e91b";
  color: rgb(36, 99, 172);
}
.icon-file-img .path2:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-file-pdf .path1:before {
  content: "\e91d";
  color: rgb(36, 99, 172);
}
.icon-file-pdf .path2:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-info-car:before {
  content: "\e91f";
  color: #2463ac;
}
.icon-info-company:before {
  content: "\e920";
  color: #5685bc;
}
.icon-info-helm:before {
  content: "\e921";
  color: #2463ac;
}
.icon-info-map:before {
  content: "\e922";
  color: #5685bc;
}
.icon-info-s:before {
  content: "\e923";
  color: #2463ac;
}
.icon-load-map:before {
  content: "\e924";
  color: #94c2f6;
}
.icon-logo-blue .path1:before {
  content: "\e925";
  color: rgb(195, 57, 75);
}
.icon-logo-blue .path2:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(195, 57, 75);
}
.icon-logo-blue .path3:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(195, 57, 75);
}
.icon-logo-blue .path4:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(195, 57, 75);
}
.icon-logo-blue .path5:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(86, 133, 188);
}
.icon-logo-blue .path6:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(86, 133, 188);
}
.icon-logo-blue .path7:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(86, 133, 188);
}
.icon-logout:before {
  content: "\e92c";
  color: #fff;
}
.icon-logo-white .path1:before {
  content: "\e92d";
  color: rgb(195, 57, 75);
}
.icon-logo-white .path2:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(195, 57, 75);
}
.icon-logo-white .path3:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(195, 57, 75);
}
.icon-logo-white .path4:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(195, 57, 75);
}
.icon-logo-white .path5:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-logo-white .path6:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-logo-white .path7:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-maps-and-flags:before {
  content: "\e934";
  color: #4caf50;
}
.icon-message:before {
  content: "\e935";
  color: #2463ac;
}
.icon-message-delivered:before {
  content: "\e936";
  color: #aab1cc;
}
.icon-message-viewed:before {
  content: "\e937";
  color: #4caf50;
}
.icon-more:before {
  content: "\e938";
  color: #6b6f81;
}
.icon-no-icon:before {
  content: "\e939";
  color: #e58e44;
}
.icon-pick-up .path1:before {
  content: "\e93a";
  color: rgb(36, 99, 172);
}
.icon-pick-up .path2:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(35, 99, 173);
}
.icon-pick-up .path3:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(36, 99, 172);
}
.icon-profile:before {
  content: "\e93d";
  color: #94c2f6;
}
.icon-save:before {
  content: "\e93e";
  color: #5685bc;
}
.icon-search:before {
  content: "\e93f";
  color: #aab1cc;
}
.icon-send-message:before {
  content: "\e940";
  color: #aab1cc;
}
.icon-shipments-closed:before {
  content: "\e941";
  color: #94c2f6;
}
.icon-shipments-new:before {
  content: "\e942";
  color: #94c2f6;
}
.icon-shipments-on-route:before {
  content: "\e943";
  color: #94c2f6;
}
.icon-tracking:before {
  content: "\e944";
  color: #2463ac;
}
.icon-unit-map:before {
  content: "\e945";
  color: #94c2f6;
}
.icon-upload .path1:before {
  content: "\e946";
  color: rgb(243, 245, 254);
}
.icon-upload .path2:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(170, 177, 204);
}
.icon-user:before {
  content: "\e948";
  color: #2463ac;
}
.icon-users:before {
  content: "\e949";
  color: #94c2f6;
}
