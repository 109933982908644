@use 'color-schema' as cs;

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.ovn-toast {
  position: relative;
  border-radius: 10px;
  background-color: cs.$background;
  padding: 10px 50px 10px 70px;
  pointer-events: auto;
  width: 350px;
  min-height: 70px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2px;

  &:not(:first-of-type) {
    margin-top: 10px;
  }

  &:hover {
    box-shadow: 0 3px 6px #00000029;
  }
}

.ovn-toast-error {
  background: cs.$background url("/assets/icons/global/t-error.svg") no-repeat 15px 50%;
}

.ovn-toast-info {
  background: cs.$background url("/assets/icons/global/t-notification.svg") no-repeat 15px 50%;
}

.ovn-toast-success {
  background: cs.$background url("/assets/icons/global/t-success.svg") no-repeat 15px 50%;
}

.ovn-toast-warning {
  background: cs.$background url("/assets/icons/global/t-warning.svg") no-repeat 15px 50%;
}

.toast-container.toast-container .toast-close-button {
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -12px;
  width: 24px;
  height: 24px;
  opacity: 1;
  border-radius: 4px;
  background-color: cs.$background;

  &:hover {
    opacity: 1;
    background-color: cs.$border-color;
  }

  span {
    font-size: 18px;
  }
}

.toast-title {
  color: cs.$text-color;
  font-size: 16px;
  font-family: 'Avenir Heavy', sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toast-message {
  color: cs.$light-text-color;
  font-size: 15px;
}
