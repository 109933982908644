@use 'color-schema' as cs;

.cdk-overlay-pane.mat-mdc-dialog-panel.mat-mdc-dialog-panel {
  max-width: 100%;
}

.content-viewer-dialog-container {
  background-color: rgba(0, 0, 0, 0.5);

  .mat-mdc-dialog-container {
    max-width: 100%;
    max-height: 100%;
  }

  .mat-mdc-dialog-surface {
    box-shadow: unset;
    border-radius: 0;
    background-color: unset;
  }
}

.confirm-dialog {
  background-color: cs.$color-white;
  box-shadow: 0 3px 20px #00000029;

  .mat-mdc-dialog-container {
    max-width: 100%;
    max-height: 100%;
  }

  .mat-mdc-dialog-surface {
    box-shadow: unset;
    border-radius: 0;
    background-color: unset;
  }
}

.base-dialog-container {
  background-color: cs.$color-white;
  box-shadow: 0 3px 20px #00000029;

  .mat-mdc-dialog-container {
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }

  .mat-mdc-dialog-surface {
    box-shadow: unset;
    border-radius: 0;
    background-color: unset;
    overflow: hidden;
  }
}

.base-dialog {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  width: 100%;
  height: 100%;
  flex: 1;

  &.compact {
    padding: 0 20px;

    .dialog-header {
      align-items: flex-start;
      padding: 20px 0;

      .title-container {
        font-size: 20px;
      }

      .dialog-close {
        margin-right: -10px;

        @media screen and (max-width: 767px) {
          margin-right: 0;
        }

        i {
          font-size: 16px;
        }
      }
    }

    .dialog-content {
      padding: 20px 10px;
    }
    .dialog-actions {
      padding: 20px 0;
    }
  }

  @media screen and (max-width: 767px) {
    &.base-dialog {
      padding: 0 5px;
    }
  }

  .dialog-header {
    display: flex;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    margin: 0 10px;

    &:not(.no-border) {
      border-bottom: 1px solid #D0DEFC;
    }

    @media screen and (max-width: 767px) {
      padding: 15px 0;
    }

    .title-container {
      font-size: 24px;
      font-family: 'Avenir Roman', sans-serif;
      text-overflow: ellipsis;
      overflow: hidden;

      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
  }

  .dialog-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
  }
  .dialog-content {
    flex: 1 1 auto;
    overflow: auto;
    padding: 30px 10px;

    &.no-padding-top {
      padding-top: 0;
    }
  }

  .dialog-actions {
    padding: 30px 0;
    margin: 0 10px;
    border-top: 1px solid #D0DEFC;

    @media screen and (max-width: 767px) {
      padding: 15px 0;
    }
  }
}
