@use 'color-schema' as cs;

.table-container {
  position: relative;
  overflow: auto;
  flex: 1 1 auto;
}

.basic-table.basic-table {
  background-color: transparent;

  .mat-mdc-header-row {
    font-size: 14px;
    font-family: 'Avenir Heavy', sans-serif;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: normal;
    min-height: 38px;
    margin-bottom: 10px;
    background-color: cs.$light-light;
    border-radius: 14px;
    position: sticky;
    top: 0;

    .mat-mdc-header-cell {
      letter-spacing: 0;
      padding: 5px 30px;
      border: unset;
      background-color: transparent;

      &:first-of-type {
        padding-left: 30px;
      }

      &:last-of-type {
        padding-right: 30px;
      }
    }
  }

  mat-row ,
  mat-footer-row {
    font-size: 16px;
    font-family: 'Avenir Book', sans-serif;
    border-left: 1px solid cs.$border-color;
    border-right: 1px solid cs.$border-color;

    &:first-of-type {
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
      border-top: 1px solid cs.$border-color;
    }
    &:last-of-type {
      border-bottom-left-radius: 14px;
      border-bottom-right-radius: 14px;
      border-bottom: 1px solid cs.$border-color;
    }
  }

  .mat-mdc-cell {
    letter-spacing: 0;
    padding: 10px 30px;
    border-bottom-color: cs.$border-color;

    &:not(:first-child) {
      border-left: 1px solid cs.$border-color;
    }

    &:first-of-type {
      padding-left: 30px;
    }

    &:last-of-type {
      padding-right: 30px;
    }
  }

  .mat-column-actions {
    flex: 0 0 91px;

    .more-btn {
      width: 30px;
      height: 30px;
      background-color: cs.$border-color;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 18px;

        &:before {
          color: cs.$color-primary;
        }
      }
    }
  }
}
