@use "color-schema" as cs;

.mat-mdc-form-field-bottom-align.mat-mdc-form-field-bottom-align::before {
  content: unset;
}

.mat-mdc-form-field {

  .mat-mdc-text-field-wrapper {
    background-color: cs.$color-white;
  }

  .mat-mdc-form-field-hint-wrapper {
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0;
    color: cs.$grey-color;
    padding: 3px 0 0;
    font-family: 'Avenir Medium', sans-serif;
    animation: unset;
  }

  .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
    letter-spacing: 0;
  }

  &.mdc-field-medium {
    font-size: 14px;
    line-height: 20px;
    font-family: 'Avenir Medium', sans-serif;
    color: cs.$light-text-color;

    &.medium {
      font-family: 'Avenir Medium', sans-serif;
    }

    &.heavy {
      font-family: 'Avenir Heavy', sans-serif;
    }

    &.field-control {
      font-size: 16px;
    }

    .mat-mdc-form-field-infix {
      padding-top: 10px;
      padding-bottom: 10px;
      min-height: 40px;
      width: 100%;
    }

    .mat-mdc-text-field-wrapper {
      padding-left: 15px;
      padding-right: 15px;

      .mdc-floating-label {
        color: cs.$light-text-color !important;
      }
    }

    .mdc-text-field--outlined {

      .mdc-notched-outline__leading {
        border-radius: 6px 0 0 6px ;
      }

      .mdc-notched-outline__trailing {
        border-radius: 0 6px 6px 0;
      }

      &:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mat-mdc-notch-piece ,
      .mat-mdc-notch-piece {
        border-color: cs.$border-color;
      }

      &.mdc-text-field--disabled .mdc-text-field__input {
        color: rgba(cs.$text-color, 0.7);
      }
    }
  }

  &.mdc-field-large {
    font-size: 18px;
    line-height: 24px;
    font-family: 'Avenir Medium', sans-serif;
    color: cs.$light-text-color;

    .mat-mdc-form-field-infix {
      padding-top: 13px;
      padding-bottom: 13px;
      min-height: 50px;
      width: 100%;
    }

    .mat-mdc-text-field-wrapper {
      padding-left: 20px;
      padding-right: 20px;

      .mdc-floating-label {
        color: cs.$light-text-color !important;
      }
    }

    .mdc-text-field--outlined {

      .mdc-notched-outline__leading {
        border-radius: 10px 0 0 10px ;
      }

      .mdc-notched-outline__trailing {
        border-radius: 0 10px 10px 0;
      }

      &:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mat-mdc-notch-piece ,
      .mat-mdc-notch-piece {
        border-color: cs.$border-color;
      }
    }
  }
}

.mat-mdc-select.mat-mdc-select {
  font-size: 14px;
  line-height: 20px;
  font-family: 'Avenir Medium', sans-serif;
  font-weight: normal;
  letter-spacing: 0;

  .mat-mdc-select-arrow-wrapper {
    height: 20px;
  }
}

.mat-mdc-checkbox.mat-mdc-checkbox {

  .mdc-checkbox {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0;

    .mdc-checkbox__checkmark {
      right: 2px;
      top: 2px;
      bottom: 2px;
      left: 2px;
      width: auto;
    }

    .mdc-checkbox__ripple ,
    .mat-mdc-checkbox-touch-target {
      display: none;
    }

    .mdc-checkbox__background ,
    .mdc-checkbox__native-control {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
    }

    .mdc-checkbox__background {
      border: 1px solid cs.$border-color;
      border-radius: 4px;
      background-color: cs.$color-white;
    }
  }

  .mat-internal-form-field {
    font-size: 14px;
    line-height: 20px;
    font-family: 'Avenir Medium', sans-serif;
    letter-spacing: 0;
    font-weight: normal;
    color: cs.$light-text-color;
  }

  .mdc-label {
    padding-left: 15px;
  }

  &.mat-mdc-checkbox-checked {

    .mat-internal-form-field {
      color: cs.$text-color;
    }

    .mdc-checkbox__native-control:checked~.mdc-checkbox__background {
      background-color: cs.$color-primary;
      border-color: cs.$color-primary;
    }
  }

  .mdc-checkbox:hover>.mdc-checkbox__native-control:not(:checked)~.mdc-checkbox__background,
  .mdc-checkbox:hover>.mdc-checkbox__native-control:not(:indeterminate)~.mdc-checkbox__background {
    border: 1px solid cs.$color-primary;
  }

  &.mat-mdc-checkbox-disabled {

    label {
      color: inherit;
    }
  }
}

.fields-section {
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: cs.$background;

  &.light {
    border-radius: 10px;
    background-color: cs.$light-light;
  }

  .fields-section-title {
    font-size: 18px;
    line-height: 24px;
    font-family: 'Avenir Heavy', sans-serif;
  }

  .fields-section-action {
    height: 24px;
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 18px;
    font-family: 'Avenir Roman', sans-serif;
    color: cs.$grey-color;

    i {
      font-size: 38px;
    }
  }
}

.clear-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  cursor: pointer;
  border: unset;
  border-radius: 50%;
  background-color: cs.$grey-color;

  i {
    font-size: 16px;

    &:before {
      color: cs.$text-color;
    }
  }
}


.clear-field {
  position: absolute;
  top: -5px;
  right: -5px;
}

.field-inline-container {
  height: 40px;
  display: flex;
  align-items: center;
}

.tabs-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .tabs-nav {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid cs.$border-color;
    gap: 2px;

    .tabs-nav-item {
      height: 40px;
      padding: 0 50px;
      border-radius: 10px 10px 0 0;
      background-color: cs.$light-light;
      color: cs.$light-text-color;
      font-size: 18px;
      font-family: 'Avenir Book', sans-serif;

      @media screen and (max-width: 767px) {
        padding: 0 30px;
      }
      @media screen and (max-width: 600px) {
        padding: 0 22px;
      }
      @media screen and (max-width: 479px) {
        padding: 0 15px;
        font-size: 14px;
      }

      &.active-tab {
        background-color: cs.$color-primary-dark;
        color: cs.$color-white;
      }
    }
  }

  .tabs-content {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    flex: 1 1 0;

    .tabs-item {
      overflow: auto;
      min-height: 100%;
    }
  }
}

.info-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  min-width: 50px;
  background-color: cs.$light-light;
  border: 1px solid cs.$border-color;
  border-radius: 16px;

  i {
    font-size: 28px;
  }
}

.mat-datepicker-content.mat-datepicker-content {
  border-radius: 4px;
  background-color: cs.$color-white;
  box-shadow: 0 1px 4px #6B6B6B29;

  .mat-calendar {
    font-size: 14px;
    font-family: 'Avenir Medium', sans-serif;
    height: auto;
    width: 310px;

    .mat-calendar-controls {
      margin: 0;
      justify-content: space-between;

      .mat-calendar-period-button {
        margin: 0;
        font-size: 16px;
        font-family: 'Avenir Heavy', sans-serif;
        padding: 0 10px;
        letter-spacing: 0;
        font-weight: normal;
        background-color: transparent;

        order: 2
      }

      .mat-calendar-spacer {
        display: none;
      }

      .mat-calendar-previous-button {
        order: 1;
        color: cs.$grey-color;
      }

      .mat-calendar-next-button {
        order: 3;
        color: cs.$grey-color;
      }
    }

    .mat-calendar-header {
      padding: 11px 27px;
    }

    .mat-calendar-content {
      padding: 0 30px 15px;

      .mat-calendar-table-header-divider {
        display: none;
      }

      .mat-calendar-table-header th {
        font-size: 12px;
        line-height: 16px;
        font-family: 'Avenir Medium', sans-serif;
        padding-bottom: 9px;
        font-weight: normal;
      }

      .mat-calendar-body-label {
        font-size: 0 !important;
        padding: 0 !important;
      }

      .mat-calendar-body-cell-content {
        border-radius: 8px;
        font-size: 14px;
        font-family: 'Avenir Medium', sans-serif;
      }

      .mat-calendar-body-cell {

        .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
          border-color: cs.$color-primary-dark;
        }

        &:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
          background-color: cs.$border-color;
        }

        &.mat-calendar-body-selected {
          background-color: cs.$color-primary-dark;
        }
      }
    }
  }
}
