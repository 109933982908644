@use 'color-schema' as cs;


/* map info window */

.gm-style .gm-style-iw-c {
  padding: 0 !important;
  width: 460px;
}

.gm-style-iw-chr {
  margin-right: 30px;
  margin-left: 30px;
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .gm-style-iw-ch {
    display: none;
  }

  button {
    color: cs.$color-primary-dark;
    font-family: 'Roboto Regular', sans-serif;
    width: 24px !important;
    height: 24px !important;

    span {
      margin: 0 !important;
    }
  }
}

.gm-style-iw-d {
  padding: 0 !important;
  display: contents !important;
}

.gm-style-iw-d__ {
  padding-top: 10px;

  .map-info-window-title {
    color: cs.$color-primary-dark;
    font-family: 'Roboto Medium', sans-serif;
    font-size: 15px;
    position: absolute;
    top: 17px;
  }

  map-info-window {
    min-width: 300px;
    border-radius: 8px;
    border: unset;
    box-shadow: 0 3px 6px #00000029;

    .unit-number, driver-phone {
      color: cs.$color-primary-dark;
      font-size: 14px;
      font-family: 'Roboto Regular', sans-serif;
    }

    .driver-name {
      display: flex;
      gap: 5px;
      align-items: center;
      color: cs.$color-primary-dark;
      font-size: 14px;
      font-family: 'Roboto Medium', sans-serif;
    }

    .status {
      display: flex;
      align-items: center;
      position: relative;
      font-size: 12px;

      &.available {
        color: cs.$available;

        &::before {
          background-color: cs.$available;
        }
      }

      &.not_available {
        color: cs.$notAvailable;

        &::before {
          background-color: cs.$notAvailable;
        }
      }

      &.available_on_route {
        color: cs.$availableOnRoute;

        &::before {
          background-color: cs.$availableOnRoute;
        }
      }

      &.on-route {
        color: cs.$onRoute;

        &::before {
          background-color: cs.$onRoute;
        }
      }
    }

    .vehicle {
      border-radius: 8px;
      border: 1px solid cs.$color-primary-light;
      padding: 9px 15px;
    }

    .info-key {
      color: cs.$color-primary-light;
    }

    .last-tracked-value {
      color: cs.$color-primary;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .info-value {
      color: cs.$color-primary-dark;
      font-size: 14px;
    }

    .last-tracked-time {
      font-size: 13px;
    }
  }
}

.unit-map-info {
  position: relative;
  padding: 0 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .title {
    font-size: 18px;
    line-height: 24px;
    font-family: 'Avenir Heavy', sans-serif;
    left: 30px;
    top: -42px;
    position: absolute;
  }

  .data-container {
    padding: 15px 20px;
    border-radius: 16px;
    background-color: cs.$light-light;
  }

  .car-data {
    display: flex;
    gap: 20px;
    align-items: center;
    font-size: 16px;

    .value {
      font-family: 'Avenir Book', sans-serif;
    }
  }

  .load-data {
    font-size: 16px;
    font-family: 'Avenir Book Oblique', sans-serif;
  }

  .driver-item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: 'Avenir Roman', sans-serif;
  }

  .flag-container {
    width: 36px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: cs.$color-white;
    border: 1px solid cs.$border-color;
    border-radius: 4px;

    img {
      width: 30px;
      height: auto;
    }
  }

  .img-container {
    border-radius: 4px;
    overflow: hidden;

    img {
      height: 26px;
      width: auto;
      display: block;
    }
  }

  .last-tracked {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .label {
      font-size: 16px;
      font-family: 'Avenir Roman', sans-serif;
      color: cs.$grey-color;
    }

    .address {
      font-size: 18px;
      font-family: 'Avenir Heavy', sans-serif;
      color: cs.$color-primary;
    }

    .time {
      font-size: 16px;
      font-family: 'Avenir Book', sans-serif;
    }
  }
}

.marker-label {
  position: absolute;
  top: 6px;
  left: 50%;
  transform: translateX(-50%);
  background: cs.$color-white;
  padding: 6px;
  box-shadow: 0 3px 20px #00000029;
  border-radius: 8px;
  font-family: 'Avenir Book', sans-serif !important;
  font-size: 12px !important;
}
.marker-label::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: cs.$color-white transparent transparent transparent;
}

