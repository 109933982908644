@use '@angular/material' as mat;
@use 'reset' as reset;
@use 'fonts' as fonts;
@use 'flex' as flex;
@use 'form-fields' as ff;
@use 'buttons' as btn;
@use 'menu' as menu;
@use 'dialog' as dialog;
@use 'color-schema' as cs;
@use 'toastr' as ts;
@use 'table' as table;
@use 'map' as map;

html {
  @include mat.theme((
    color: (
      theme-type: light,
      primary: mat.$azure-palette,
      tertiary: mat.$blue-palette,
    ),
    typography: Roboto,
    density: 0,
  ));
}

html, body {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Avenir Medium', sans-serif;
  font-size: 14px;
  line-height: 1.4;
  color: cs.$text-color;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  -webkit-appearance: none;
}

::-webkit-scrollbar-track {
  background: cs.$color-white;
  border-radius: 5px;
  border: 1px solid cs.$border-color;
}

::-webkit-scrollbar-thumb {
  background: cs.$border-color;
  border-radius: 5px;
}

::-webkit-scrollbar-button {
  display: none;
}

.link {
  text-decoration: none;
  color: cs.$color-primary-dark;

  &.inherit {
    color: inherit;
  }

  &:hover {
    text-decoration: underline;
  }
}

textarea::placeholder ,
input::placeholder {
  color: cs.$light-text-color
}

[class^=icon-] {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.pos-relative {
  position: relative;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.full-size {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  min-width: 1px;
}

.no-overflow {
  overflow: hidden;
}

.scrolled {
  overflow: auto;
}

.scroll-padding {
  padding-left: 10px;
  padding-right: 10px;
}

.scroll-margin {
  margin-left: 10px;
  margin-right: 10px;
}

.clickable {
  cursor: pointer;
}

.divider-h {
  border-top: 1px solid #D0DEFC;
}
.divider-v {
  border-left: 1px solid #D0DEFC;
}

.border-top {
  border-top: 1px solid #D0DEFC;
}

.no-wrap {
  white-space: nowrap;
}

.list-load-next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
}

.ovn-loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;

  &.blur {

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(cs.$background, 0.4);
      content: '';
      z-index: 1;
    }
  }
}

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  overflow: hidden;
}

b ,
.bold-text {
  font-weight: normal;
  font-family: 'Avenir Heavy', sans-serif;
}

.title-lined {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-size: 16px;
  font-family: 'Avenir Heavy', sans-serif;

  &:before {
    content: '';
    flex: 1 1 0;
    border-top: 1px solid cs.$border-color;
  }
  &:after {
    content: '';
    flex: 1 1 0;
    border-top: 1px solid cs.$border-color;
  }
}

.text-uppercase {
  text-transform: uppercase;
}

.component-filter {
  background-color: cs.$background;
  border-radius: 10px;
  padding: 20px;

  .filter-form {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media screen and (max-width: 700px) {
      flex-direction: column;
    }

    .query-field {
      width: 280px;
    }

    .filter-item {
      width: 300px;
    }
  }

  .btn-toggle-container {
    display: flex;
  }

  .unit-map-filter-flag {
    padding: 5px 20px;
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: cs.$color-white;
    border: 1px solid cs.$border-color;
    height: 40px;
    user-select: none;
    color: cs.$light-text-color;
    font-size: 12px;

    &.on {
      background-color: cs.$color-primary;
      border-color: cs.$color-primary;
      color: cs.$color-white;

      i:before {
        color: inherit;
      }
    }

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    i {
      font-size: 30px;

      &:before {
        color: cs.$grey-color;
      }
    }
  }

  .actions {
    display: flex;
    gap: 20px;

    button {
      height: 40px;
    }
  }
}

.empty-content {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  font-family: 'Avenir Roman', sans-serif;
}

:root {
  @include mat.tooltip-overrides((
          container-color: cs.$background,
          supporting-text-color: cs.$text-color,
  ));
}

.mat-mdc-tooltip-surface.mat-mdc-tooltip-surface {
  font-size: 13px;
  padding: 5px 10px;
  font-family: 'Roboto Medium', sans-serif;
  box-shadow: 0 3px 20px #00000029;
}

.default-tooltip {

  .mat-mdc-tooltip-surface {
    box-shadow: 0 3px 20px #00000029;
    max-width: 400px;
    padding: 15px 15px 15px 50px;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Roboto Medium', sans-serif;
    border-radius: 10px;

    &:before {
      position: absolute;
      left: 15px;
      top: 12px;

      width: 24px;
      height: 24px;
      background: url("/assets/icons/global/attention.svg") no-repeat center center;
    }
  }
}

.pro-container {
  height: 28px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;

  .pro {
    flex: 1;
    padding: 6px 15px;
    border-radius: 10px;
    border: 1px solid cs.$border-color;
    font-size: 18px;
    font-family: 'Avenir Book', sans-serif;
    display: flex;
    gap: 15px;
    color: cs.$color-primary-dark;

    .number {
      flex: 1;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
