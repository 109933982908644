$color-primary-dark: #2463AC;
$color-primary: #5685BC;
$color-primary-light: #94C2F6;

$color-secondary-dark: #B41228;
$color-secondary: #C3394B;
$color-secondary-light: #FFC4CB;
$alternate-green: #4CAF50;

$alternate-purple: #866FBA;

$color-black: #000000;
$text-color: #1F1F1F;
$light-text-color: #6B6F81;
$grey-color: #AAB1CC;
$border-color: #D0DEFC;
$light-light: #F3F5FE;
$background: #F8F9FD;
$color-white: #FFFFFF;

// UNIT MAP
$available: $alternate-green;
$notAvailable: $color-secondary;
$availableOnRoute: #e5a100;
$onRoute: $light-text-color;
