@use "color-schema" as cs;

.btn {
  padding: 5px 15px;
  height: 40px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-size: 16px;
  font-family: 'Avenir Medium', sans-serif;
  background-color: transparent;
  white-space: nowrap;

  &.min-text {
    font-size: 14px;
    gap: 10px;

    i {
      font-size: 22px;
    }
  }

  i {
    font-size: 24px;

    &:before {
      color: inherit;
    }
  }

  &.with-spinner {
    position: relative;

    mat-spinner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      & + .button-text {
        opacity: 0;
      }
    }
  }

  &:not([disabled]) {
    &:hover {

      i.icon-logo-blue {
        [class^="path"] {

          &:before {
            color: cs.$color-white;
          }
        }
      }
    }

    &.primary {
      border: 2px solid cs.$color-primary;
      color: cs.$color-primary;

      &:hover {
        color: cs.$color-white;
        background-color: cs.$color-primary;
      }
    }

    &.secondary {
      border: 2px solid cs.$color-secondary;
      color: cs.$color-secondary;

      &:hover {
        color: cs.$color-white;
        background-color: cs.$color-secondary;
      }
    }

    &.alternate-purple {
      border: 2px solid cs.$alternate-purple;
      color: cs.$alternate-purple;

      &:hover {
        color: cs.$color-white;
        background-color: cs.$alternate-purple;
      }
    }

    &.alternate-green {
      border: 2px solid cs.$alternate-green;
      color: cs.$alternate-green;

      &:hover {
        color: cs.$color-white;
        background-color: cs.$alternate-green;
      }
    }

    &.light-text {
      border: 2px solid cs.$light-text-color;
      color: cs.$light-text-color;

      &:hover {
        color: cs.$color-white;
        background-color: cs.$light-text-color;
      }
    }

    &.border-color {
      height: 40px;
      padding: 5px 15px;

      border: 2px solid cs.$border-color;
      background-color: cs.$border-color;
      color: cs.$color-primary-dark;

      &:hover {
        background-color: cs.$color-white;
      }

      &.more {
        width: 40px;
        padding: 0;
      }
    }
  }

  &.btn[disabled] ,
  &.disabled {
    border: 2px solid cs.$grey-color;
    color: cs.$grey-color;
    cursor: default;

    i.icon-logo-blue {
      [class^="path"] {

        &:before {
          color: cs.$grey-color;
        }
      }
    }
  }

}

.btn-more {
  padding: 5px 15px;
  border-radius: 20px;
  height: 34px;
  font-size: 14px;
  font-family: 'Avenir Medium', sans-serif;
  color: cs.$color-primary-dark;
  border: 2px solid cs.$border-color;
  background-color: cs.$border-color;

  &:hover {
    background-color: cs.$color-white;
  }
}

.btn-add {
  padding: 0 15px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 15px;
  color: cs.$color-white;
  font-size: 15px;
  font-family: 'Avenir Heavy', sans-serif;
  border: 2px solid cs.$alternate-green;
  background-color: cs.$alternate-green;
  white-space: nowrap;

  i {
    font-size: 24px;

    &:before {
      color: inherit;
    }
  }

  &:hover {
    background-color: cs.$color-white;
    color: cs.$alternate-green;;
  }
}
